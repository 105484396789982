export const CONFIG = {
  baseApiUrl: 'https://ekmvu0m3lf.execute-api.ap-southeast-2.amazonaws.com/prod',
  printerProxyApiUrl: 'https://z6fi1qjcsj.execute-api.ap-southeast-2.amazonaws.com/prod',
  userApiUrl: 'https://emncxniiy8.execute-api.ap-southeast-2.amazonaws.com/prod',
  printerProxyUrl: 'https://printer-proxy.tec.fit',
  videoFeedBaseUrl: 'wss://printer-proxy.tec.fit',
  duetBaseUrl: 'https://prod.d8iyb9k59ez3z.amplifyapp.com/',
  apiStage: 'prod'
}

export const AWS_CONFIG = {
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_8ZWjujjDk',
    userPoolWebClientId: '3ghsc76jchv37e49n40cpc1t6a'
  }
}